var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.location)?_c('div',[(_vm.showDeleteImage)?_c('ModalDeletePhoto',{attrs:{"showModal":_vm.showDeleteImage,"onClickCancel":_vm.hideModal,"image":_vm.selectedImage}}):_vm._e(),_c('ui-page-hero',{attrs:{"id":"id_title_impressionphotos","title":'Images'}},[_c('template',{slot:"quickActions"},[_c('a',{staticClass:"has-icon",on:{"click":function($event){_vm.startTutorial()
          _vm.restartTutorial = true}}},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'redo']}})],1),_c('span',{staticClass:"text"},[_vm._v("Restart tutorial")])])])],2),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',[_c('div',{staticClass:"section is-medium no-padding-top"},[(_vm.fullSizePreview)?_c('ModalImagePreview',{attrs:{"showModal":_vm.fullSizePreview,"onClickCancel":_vm.hideFullSize,"image":_vm.previewImage}}):_vm._e(),_c('div',{staticClass:"columns is-mobile is-multiline is-vcentered"},[_c('div',{staticClass:"column is-5"},[_c('ImageCropper',{ref:"imageCropper",attrs:{"aspectRatio":4 / 3,"guides":false,"viewMode":3,"previewContainer":".imagePreview","aspectRatioCssClass":"is-4by3"},on:{"callbackImageChosen":(val) => {
                  _vm.newImageChosen = val
                },"errorCallbackInvalid":(val) => {
                  _vm.hasImageError = val
                }}})],1),_c('div',{staticClass:"column is-5"},[(_vm.newImageChosen)?[_c('div',{staticClass:"wrapper-previews mb-6"},[_c('div',{staticClass:"imagePreview has-background-grey-light"})]),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button-preview button is-outlined",on:{"click":function($event){return _vm.showFullSize(null, true)}}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":['fa', 'expand-alt']}})],1),_c('span',[_vm._v(_vm._s(_vm.$t('Form.Control.Preview')))])]),_c('a',{staticClass:"button is-success",class:{ 'is-loading': _vm.isUploading },attrs:{"disabled":_vm.isUploading || _vm.hasImageError},on:{"click":function($event){return _vm.uploadAndSave()}}},[_vm._v(" "+_vm._s(_vm.$t('Form.Control.Upload')))])])]:_vm._e()],2)])],1),_c('div',{staticClass:"section-previews section is-medium"},[_c('div',{staticClass:"columns is-mobile is-multiline"},[_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"title is-3"},[_c('span',{attrs:{"id":"id_title_uploadedimages"}},[_vm._v("Uploaded images")])]),_c('Message',{attrs:{"message":'Here you can set a default image and/or remove images.'}})],1),_vm._l((_vm.location.Images),function(imageData){return _c('div',{key:'image' + imageData.Id,staticClass:"column is-one-third-tablet is-one-third-desktop is-one-quarter-widescreen"},[_c('div',{staticClass:"card",class:{ 'is-active': imageData.IsDefault }},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"locationImageContainer mb-4"},[_c('ImageViewer',{staticClass:"is-4by3",attrs:{"type":"location","imageSrc":imageData.Image,"itemName":imageData.Image,"itemId":imageData.LocationId,"imageSize":640,"aspectRatio":4 / 3}}),_c('button',{staticClass:"button is-small",on:{"click":function($event){_vm.showFullSize(
                        _vm.$options.filters.getLocationImage(
                          imageData.Image,
                          imageData.LocationId
                        )
                      )}}},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":['fa', 'expand-alt']}})],1)])],1),_c('div',{staticClass:"has-text-right"},[_c('a',{staticClass:"has-text-info has-margin-right",on:{"click":function($event){return _vm.saveLocationDefaultImage(imageData)}}},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":['fa', 'star']}})],1),_c('span',[_vm._v("Set as hero")])]),_c('a',{staticClass:"has-text-danger",on:{"click":function($event){return _vm.setShowDeleteImage(imageData)}}},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":['fa', 'trash-alt']}})],1)])])])])])})],2)])])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }