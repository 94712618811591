<template>
  <div v-if="location">
    <ModalDeletePhoto
      v-if="showDeleteImage"
      :showModal="showDeleteImage"
      :onClickCancel="hideModal"
      :image="selectedImage"
    />

    <ui-page-hero id="id_title_impressionphotos" :title="'Images'">
      <template slot="quickActions">
        <a
          @click="
            startTutorial()
            restartTutorial = true
          "
          class="has-icon"
        >
          <span class="icon">
            <font-awesome-icon :icon="['fas', 'redo']"
          /></span>
          <span class="text">Restart tutorial</span>
        </a>
      </template>
    </ui-page-hero>

    <transition name="fade" mode="out-in">
      <!-- Start of 'Impression Photos' column -->
      <div>
        <div class="section is-medium no-padding-top">
          <ModalImagePreview
            v-if="fullSizePreview"
            :showModal="fullSizePreview"
            :onClickCancel="hideFullSize"
            :image="previewImage"
          />

          <!-- Start of 'Choose Image' and 'Preview' column -->
          <div class="columns is-mobile is-multiline is-vcentered">
            <div class="column is-5">
              <ImageCropper
                ref="imageCropper"
                :aspectRatio="4 / 3"
                :guides="false"
                :viewMode="3"
                previewContainer=".imagePreview"
                @callbackImageChosen="
                  (val) => {
                    newImageChosen = val
                  }
                "
                @errorCallbackInvalid="
                  (val) => {
                    hasImageError = val
                  }
                "
                aspectRatioCssClass="is-4by3"
              />
            </div>
            <div class="column is-5">
              <template v-if="newImageChosen">
                <div class="wrapper-previews mb-6">
                  <div class="imagePreview has-background-grey-light"></div>
                </div>

                <div class="buttons">
                  <button
                    @click="showFullSize(null, true)"
                    class="button-preview button is-outlined"
                  >
                    <span class="icon is-small">
                      <font-awesome-icon :icon="['fa', 'expand-alt']"
                    /></span>
                    <span>{{ $t('Form.Control.Preview') }}</span>
                  </button>
                  <a
                    @click="uploadAndSave()"
                    :class="{ 'is-loading': isUploading }"
                    :disabled="isUploading || hasImageError"
                    class="button is-success"
                  >
                    {{ $t('Form.Control.Upload') }}</a
                  >
                </div>
              </template>
            </div>
          </div>
        </div>

        <!-- Start of 'Photos' column -->
        <div class="section-previews section is-medium">
          <!-- start of photo dump section -->
          <div class="columns is-mobile is-multiline">
            <div class="column is-full">
              <div class="title is-3">
                <span id="id_title_uploadedimages">Uploaded images</span>
              </div>
              <Message
                :message="'Here you can set a default image and/or remove images.'"
              />
            </div>
            <div
              v-for="imageData in location.Images"
              :key="'image' + imageData.Id"
              class="column is-one-third-tablet is-one-third-desktop is-one-quarter-widescreen"
            >
              <div class="card" :class="{ 'is-active': imageData.IsDefault }">
                <div class="card-content">
                  <div class="locationImageContainer mb-4">
                    <ImageViewer
                      class="is-4by3"
                      type="location"
                      :imageSrc="imageData.Image"
                      :itemName="imageData.Image"
                      :itemId="imageData.LocationId"
                      :imageSize="640"
                      :aspectRatio="4 / 3"
                    />
                    <button
                      @click="
                        showFullSize(
                          $options.filters.getLocationImage(
                            imageData.Image,
                            imageData.LocationId
                          )
                        )
                      "
                      class="button is-small"
                    >
                      <span class="icon">
                        <font-awesome-icon :icon="['fa', 'expand-alt']"
                      /></span>
                    </button>
                  </div>
                  <div class="has-text-right">
                    <a
                      @click="saveLocationDefaultImage(imageData)"
                      class="has-text-info has-margin-right"
                    >
                      <span class="icon">
                        <font-awesome-icon :icon="['fa', 'star']" />
                      </span>
                      <span>Set as hero</span>
                    </a>
                    <a
                      @click="setShowDeleteImage(imageData)"
                      class="has-text-danger"
                    >
                      <span class="icon">
                        <font-awesome-icon :icon="['fa', 'trash-alt']" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { cleanSource } from '@/utils/objectHelper.js'
import uploadProvider from '@/providers/upload'
import locationProvider from '@/providers/location'
import EventBus from '../../eventbus/event-bus'

const ImageCropper = () =>
  import(
    /* webpackChunkName: "location-images" */ '@/components/UI/Components/ImageCropper.vue'
  )
const ModalImagePreview = () =>
  import(
    /* webpackChunkName: "location-images" */ '@/components/ModalImagePreview'
  )
const ModalDeletePhoto = () =>
  import(
    /* webpackChunkName: "location-images" */ '@/components/Locations/ModalDeletePhoto'
  )
const Message = () =>
  import(/* webpackChunkName: "location-images" */ '@/components/UI/Message')
const ImageViewer = () =>
  import(
    /* webpackChunkName: "location-images" */ '@/components/UI/Components/ImageViewer'
  )

export default {
  components: {
    ImageCropper,
    Message,
    ModalImagePreview,
    ModalDeletePhoto,
    ImageViewer,
  },
  props: {},

  data() {
    return {
      isUploading: false,
      fullSizePreview: false,
      restartTutorial: false,
      previewImage: '',
      selectedImage: null,
      showDeleteImage: false,
      newImageChosen: false,
      hasImageError: false,
    }
  },

  computed: {
    ...mapState('locationStore', ['location']),
  },

  watch: {},

  beforeCreate() {},

  created() {},

  methods: {
    ...mapMutations('locationStore', ['setLocation']),

    /**
     * Show Modal
     */
    showFullSize(imgSrc = '', isNewImage = false) {
      this.previewImage = !isNewImage
        ? imgSrc
        : this.$refs.imageCropper.getCroppedCanvas()
      this.fullSizePreview = true
    },

    /**
     * Hide modal
     */
    hideFullSize() {
      this.fullSizePreview = false
      this.previewImage = ''
    },

    saveLocationDefaultImage(imageData) {
      let self = this

      let mLocation = cleanSource(self.location)

      locationProvider.methods
        .saveLocationDefaultImage(imageData.LocationId, imageData.Id)
        .then(() => {
          let oldDefaultImg = mLocation.Images.findIndex((i) => i.IsDefault)
          if (oldDefaultImg > -1) {
            mLocation.Images[oldDefaultImg].IsDefault = false
          }

          let newDefaultImg = mLocation.Images.findIndex(
            (i) => i.Id === imageData.Id
          )
          if (newDefaultImg > -1) {
            mLocation.Images[newDefaultImg].IsDefault = true
          }

          self.setLocation(mLocation)
        })
        .catch((e) => {})
        .finally(() => {})
    },

    /**
     * Upload new image and add it to location images
     */
    async uploadAndSave() {
      if (this.isUploading || !this.newImageChosen || this.hasImageError) {
        return
      }
      this.isUploading = true

      let mLocation = cleanSource(this.location)

      try {
        const NEW_IMAGE = this.$refs.imageCropper.getCroppedCanvas()
        let responseImageUpload = await uploadProvider.methods.locationImage(
          this.location.Id,
          NEW_IMAGE
        )

        if (responseImageUpload.status === 200) {
          // Save new image to location images
          let locationSaveResponse =
            await locationProvider.methods.saveLocationImage(
              this.location.Id,
              responseImageUpload.data
            )
          mLocation.Images.push(locationSaveResponse.data)
          this.setLocation(mLocation)

          if (mLocation.Images.length === 1) {
            this.saveLocationDefaultImage(locationSaveResponse.data)
          }

          EventBus.$emit('showToast', {
            type: 'info',
            message: 'New photo has been added.',
          })
        }
      } catch {
      } finally {
        this.isUploading = false
        this.$refs.imageCropper.reset()
      }
    },

    setShowDeleteImage(image) {
      this.selectedImage = image
      this.showDeleteImage = true
    },

    hideModal() {
      this.selectedImage = null
      this.showDeleteImage = false
    },
  },
}
</script>

<style lang="sass" scoped>
.wrapper-previews
  display: flex
  flex-grow: 1
  .imagePreview
    overflow: hidden
    aspect-ratio: 4/3
    &:nth-child(1)
      max-width: 60%
      width: 100%

.locationImageContainer
  position: relative
  .button
    position: absolute
    bottom: 0
    left: 0

.is-active
  background-color: lighten(#f8fced, 50%)
</style>
